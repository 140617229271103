import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { map, isPlainObject, isArray, each, set, isEmpty, get } from "lodash";
import { v4 as uuidv4 } from "uuid";

import ControlGroup from "components/shared/fields/ControlGroup";
import Hint from "components/shared/fields/Hint";
import PropertyEdit, {
  isManualFocusHandlingRequired,
} from "components/appCreator/properties/PropertyEdit";
import { isPropRequired } from "components/appCreator/properties/helpers";
import Headline from "components/appCreator/properties/show/Headline";

function Fields({ properties, fieldErrors, appId }) {
  const [fieldIdBase] = useState(appId || uuidv4());
  const focusHandlers = useRef({});
  const handleNewInputRef = (name) => (newRef) => {
    focusHandlers.current[name] = () => newRef.focus();
  };
  const handleLabelClick = (e) => {
    const name = e.currentTarget.dataset.propertyName;
    if (focusHandlers.current[name]) focusHandlers.current[name]();
  };

  const errors = {};
  each(fieldErrors, (error, key) => {
    set(errors, key, error);
  });

  return map(properties, (prop, i) => {
    const fieldId = `${fieldIdBase}-${prop.name}`;
    const name = get(prop, "name");
    const propErrors = get(errors, [name, prop.name]) || get(errors, prop.name);
    const propHasErrors = !isEmpty(propErrors);

    if (prop.type === "statistics") return null;
    if (prop.type === "headline") return <Headline key={i} property={prop} />;

    return (
      <ControlGroup
        key={i}
        name={prop.name}
        label={prop.label}
        required={isPropRequired(prop)}
        htmlFor={fieldId}
        className={`${prop.type}-property`}
        error={propHasErrors}
        onLabelClick={handleLabelClick}
      >
        <PropertyEdit
          property={prop}
          appId={appId} // deprecated
          id={fieldId}
          error={propHasErrors}
          inputRef={
            isManualFocusHandlingRequired(prop)
              ? handleNewInputRef(prop.name)
              : null
          }
        />
        <Hint hint={prop.hint} />
        {map(
          isPlainObject(propErrors) || isArray(propErrors)
            ? propErrors
            : [propErrors],
          (error, i) => (
            <span key={i} className="help-block text-danger">
              {error}
            </span>
          ),
        )}
      </ControlGroup>
    );
  });
}

Fields.propTypes = {
  properties: PropTypes.array,
  fieldErrors: PropTypes.object,
  name: PropTypes.string,
  appId: PropTypes.string,
  options: PropTypes.object,
};

export default Fields;
