import React, { useLayoutEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { isEmpty, pick, get } from "lodash";

import { selectAppResult, selectItemResult } from "selectors/appCreator";
import { fetchItem, fetchApp } from "actions/appCreator";
import Fields from "./Fields";
import useApiResource from "../../../shared/hooks/useApiResource";
import Hint from "../../../shared/fields/Hint";

// Switches between NewItemForm and EditItemForm depending
// if any item identifier was given
function BasicItemForm(props) {
  const { loading: appLoading, data: app } = useLoadApp(
    pick(props, ["appId", "externalAppId"]),
  );

  const noItem = isEmpty(props.itemId) && isEmpty(props.externalItemId);
  const FormComponent = noItem ? NewItemForm : EditItemForm;

  return (
    <Fragment>
      {!isEmpty(get(app, "hint")) ? (
        <Hint hint={`**${I18n.t("js.apps.item.hint")}**\n\n${app.hint}`} />
      ) : null}
      <FormComponent appLoading={appLoading} app={app} {...props} />
    </Fragment>
  );
}

BasicItemForm.propTypes = {
  appId: PropTypes.string,
  externalAppId: PropTypes.string,
  itemId: PropTypes.string,
  externalItemId: PropTypes.string,
  // Callback to initialize the form with the items values
  // Make sure this only sets section values if embedding this component
  initialize: PropTypes.func,
  filterProperties: PropTypes.func,
};

function Loading() {
  return (
    <Fragment>
      <i className="fa fa-spinner fa-spin" /> {I18n.t("js.groups.loading")}
    </Fragment>
  );
}

const useLoadApp = ({ appId, externalAppId }) =>
  useApiResource({
    actionCreator: fetchApp,
    payload: { appId, externalAppId },
    selector: selectAppResult,
  });

const useLoadItem = ({ appId, externalAppId, itemId, externalItemId }) =>
  useApiResource({
    actionCreator: fetchItem,
    payload: { appId, externalAppId, itemId, externalItemId },
    selector: selectItemResult,
  });

export function NewItemForm({ app, appLoading, filterProperties }) {
  if (appLoading || isEmpty(app)) return <Loading />;

  let properties = app.properties;
  if (filterProperties) properties = properties.filter(filterProperties);

  return <Fields properties={properties} appId={app.id} filterProperties />;
}

NewItemForm.propTypes = {
  app: PropTypes.shape({ properties: PropTypes.array }),
  appLoading: PropTypes.bool,
};

function useInitialize({ initialize, appLoading, itemLoading, item }) {
  // workaround for tixxt composer firing changes on mount which override initial values
  const [initialized, setInitialized] = useState(false);

  useLayoutEffect(() => {
    if (!appLoading && !itemLoading) {
      if (!isEmpty(item))
        initialize(item.values, { updateUnregisteredFields: true });

      // workaround
      setInitialized(true);
    }
  }, [appLoading, itemLoading]);

  return initialized;
}

export function EditItemForm({
  app,
  appLoading,
  itemId,
  externalItemId,
  appId,
  externalAppId,
  initialize,
  filterProperties,
}) {
  const { loading: itemLoading, data: item } = useLoadItem({
    appId,
    externalAppId,
    itemId,
    externalItemId,
  });
  const initialized = useInitialize({
    initialize,
    appLoading,
    itemLoading,
    item,
  });

  if (appLoading || itemLoading || !initialized) return <Loading />;

  let properties = app.properties;
  if (filterProperties) properties = properties.filter(filterProperties);

  return <Fields properties={properties} appId={app.id} />;
}

EditItemForm.propTypes = {
  ...BasicItemForm.propTypes,
  ...NewItemForm.propTypes,
};

export default BasicItemForm;
